import React, { useState, useEffect, useRef } from "react";
import { Canvas } from "@react-three/fiber";
import { Vector2, TextureLoader, VideoTexture } from "three";
import AnimatedCamera from "./Camera";
import { Html } from "@react-three/drei";

import Panel from "./Panel";
import Annotation from "./Annotation";

import annotations from "../annotation.json";

const FinalScene = () => {
  const videoRef = useRef(null);
  const mouse = useRef(new Vector2());

  const clipRadius = 300;

  const [clipPosition, setClipPosition] = useState({ x: 0, y: 0 });

  const [skinTexture, setSkinTexture] = useState(null);
  const [videoTexture, setVideoTexture] = useState(null);

  const [target, setTarget] = useState(null);

  useEffect(() => {
    // Load Textures
    const textureLoader = new TextureLoader();
    const texture = textureLoader.load("/skin.jpg");
    setSkinTexture(texture);

    videoRef.current = document.createElement("video");
    videoRef.current.src = "/Comp 1.mp4";
    videoRef.current.crossOrigin = "anonymous";
    videoRef.current.loop = true;
    const video = videoRef.current;
    const videoTexture = new VideoTexture(video);
    setVideoTexture(videoTexture);
  }, []);

  const clearTarget = () => {
    setTarget(null);
  };

  useEffect(() => {
    if (target) {
      document.getElementById("panel_audio").play();
    } else {
      document.getElementById("panel_audio").pause();
      document.getElementById("panel_audio").currentTime = 0;
    }
  }, [target]);

  return (
    <div
      className="container"
      onMouseMove={(e) => {
        mouse.current.clientX = e.clientX;
        mouse.current.clientY = e.clientY;
        setClipPosition({ x: e.clientX, y: e.clientY });
      }}
      onClick={() => {
        document.documentElement.requestFullscreen();
        videoRef.current.play();
        const bgm = new Audio("/sounds/Yotam Agam - Magnetic Field 396 Hz.mp3");
        bgm.play();
      }}
    >
      {target && <Panel data={target} clearTarget={clearTarget} />}

      <audio id="panel_audio" src="/sounds/classic-radio-static-01.wav" />

      <div
        className="mask"
        style={{
          top: clipPosition.y - clipRadius,
          left: clipPosition.x - clipRadius,
          width: clipRadius * 2,
          height: clipRadius * 2,
        }}
      >
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            top: -clipPosition.y + clipRadius,
            left: -clipPosition.x + clipRadius,
          }}
        >
          <Canvas>
            <Html>
              {annotations.map((annotation) => (
                <Annotation
                  key={annotation.id}
                  data={annotation}
                  setTarget={setTarget}
                />
              ))}
            </Html>

            <mesh position={[0, 0, 0]}>
              <planeGeometry args={[500, 375]} />
              <meshBasicMaterial map={videoTexture} />
            </mesh>
            <AnimatedCamera
              mouse={mouse}
              isMoving={document.fullscreenElement && !target}
            />
          </Canvas>
        </div>
      </div>

      <div className="logo-container">
        <img src="/logo.png" alt="logo" />
        <p>마우스를 움직여 원하는 세포 위 숫자를 클릭하세요.</p>
      </div>
      <div className="logo-container">
        <img src="/logo.png" alt="logo" />
        <p>마우스를 움직여 원하는 세포 위 숫자를 클릭하세요.</p>
      </div>

      <Canvas className="canvas" flat={true}>
        <mesh>
          <planeGeometry args={[500, 375]} />
          <meshBasicMaterial map={skinTexture} />
        </mesh>

        <AnimatedCamera
          mouse={mouse}
          isMoving={document.fullscreenElement && !target}
        />
      </Canvas>
    </div>
  );
};

export default FinalScene;
