import "./App.css";
import FinalScene from "./components/FinalScene";
import Scene from "./components/Scene";

function App() {
  return (
    <div className="App">
      {/* <article
        style={{
          margin: "0 auto",
          marginTop: 60,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          zIndex: 999,
          position:
        }}
      >
        <h1 style={{ fontSize: 32, fontWeight: "bold", margin: 0 }}>
          마음무늬신인류
        </h1>
        <p>마우스를 움직여 원하는 세포 위 숫자를 클릭하세요</p>
      </article> */}
      {/* <Scene /> */}
      <FinalScene />
    </div>
  );
}

export default App;
