import { useRef } from "react";
import { useFrame, extend } from "@react-three/fiber";
import { OrthographicCamera } from "@react-three/drei";
import { Vector3 } from "three";

extend({ OrthographicCamera });

const AnimatedCamera = ({ mouse, isMoving, toggleMove }) => {
  const { innerWidth, innerHeight } = window;
  const cameraRef = useRef();
  const targetPosition = useRef(new Vector3(20, 10, 5));

  const moveSpeed = 1.5; // 카메라 이동 속도 조절
  const acceleration = 0.01; // 가속도

  useFrame(() => {
    const camera = cameraRef.current;
    if (!camera) return;

    let { clientX, clientY } = mouse.current;

    const x = (clientX / innerWidth) * 2 - 1;
    const y = -(clientY / innerHeight) * 2 + 1;

    if (isMoving) {
      if (x < -0.9 && camera.position.x > -80) {
        targetPosition.current.x -= moveSpeed; // move left
      }
      if (x > 0.9 && camera.position.x < 80) {
        targetPosition.current.x += moveSpeed; // move right
      }
      if (y > 0.9 && camera.position.y < 90) {
        targetPosition.current.y += moveSpeed; // move up
      }
      if (y < -0.9 && camera.position.y > -90) {
        targetPosition.current.y -= moveSpeed; // move down
      }
    }

    // 현재 위치와 목표 위치 사이의 거리 계산
    const distance = camera.position.distanceTo(targetPosition.current);

    // 가속도를 적용하여 이동 속도 조절
    const speed = distance * acceleration;

    // 현재 위치에서 목표 위치로 부드럽게 이동
    camera.position.lerp(targetPosition.current, Math.min(moveSpeed, speed));
  });

  return (
    <OrthographicCamera
      ref={cameraRef}
      makeDefault
      position={[0, 0, 0]}
      zoom={7}
    />
  );
};

export default AnimatedCamera;
