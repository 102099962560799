function Annotation({ data, setTarget }) {
  const { id, position } = data;

  return (
    <div
      className="annotation"
      style={{ position: "fixed", top: position[0], left: position[1] }}
      onClick={() => {
        setTarget(data);
      }}
    >
      {id}
    </div>
  );
}

export default Annotation;
