import React from "react";

const Panel = ({ data, clearTarget }) => {
  const turnOffBgm = new Audio("/sounds/Whoosh Turn Off.wav");

  const handleTurnOff = () => {
    turnOffBgm.play();
    document.getElementById("panel").style.opacity = 0;

    setTimeout(() => {
      clearTarget();
    }, 500);
  };

  return (
    <div id="panel" className={`panel ${data ? "open" : ""}`}>
      <video autoPlay loop={true} muted>
        <source src={data?.source} type="video/webm" />
      </video>
      <div className="closeBtn" onClick={handleTurnOff} />
      <div className="backdrop" />
    </div>
  );
};

export default Panel;
